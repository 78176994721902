<!--
* @description 资讯与活动视频详情详情
* @fileName zxyhdVideoDetail.vue
* @author liulian
* @date 2023/07/14 18:15:59
-->
<template>
  <div class="zxyhdVideoDetail">
    <div class="z_top1"></div>

    <div class="n_con">
      <div
        class="n_c_title font-size-32 font-weight-500 font-color-black-1D1D1F"
      >
        {{ detailInfo.videoTitle }}
      </div>
      <div class="n_c_time font-size-18 font-weight-300">
        {{ detailInfo.updateTime }} 发布
      </div>
      <div class="t_c_l_video">
        <div v-html="htmlUrl" class="c_l_v_box" v-if="htmlUrl"></div>
        <video
          id="videoId"
          muted="muted"
          class="c_l_v_box"
          ref="videoPlayer"
          controls
          autoplay
          @loadedmetadata="getVideoDuration"
          v-else
        >
          <source
            v-if="detailInfo.teachingVideoUrl"
            :src="detailInfo.teachingVideoUrl"
            type="video/mp4"
          />
        </video>
        <!-- <iframe v-if="detailInfo.teachingVideoUrl && detailInfo.teachingVideoUrl.indexOf('.mov') === -1 " style="display: none" allow="autoplay" :src="detailInfo.teachingVideoUrl"></iframe> -->
      </div>
      <div
        class="n_c_con font-size-18 font-weight-400"
        v-html="detailInfo.videoContent"
      ></div>
    </div>
    <img
      class="code_img"
      src="@/assets/images/zxyhdImg/er_code@2x.png"
      alt="二维码图"
    />
  </div>
</template>

<script>
import {
  clientVideoSelectByIdApi,
  teachingCountApi,
  listByIdsApi,
} from "@/api/api";
export default {
  name: "zxyhdVideoDetail",
  components: {},
  data() {
    return {
      videoId: null,
      detailInfo: {},
      htmlUrl: "",
    };
  },
  computed: {},
  created() {
    this.videoId = this.$route.query.id;
    this.getInfo();
  },
  mounted() {},
  methods: {
    /*
     * @Description: 获取视频
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 09:43:56
     * @param: ''
     */
    getVideoUrl(ossId) {
      return new Promise((resolve, reject) => {
        listByIdsApi(ossId).then((res) => {
          if (res.code === 200) {
            resolve(res.data);
          }
        });
      });
    },
    /*
     * @Description: 视频被点击了播放
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 10:47:17
     * @param: ''
     */
    handleVideoClick() {
      if (this.detailInfo.videoFileId) {
        teachingCountApi(this.detailInfo.videoFileId).then((res) => {
          if (res.code === 200) {
          }
        });
      }
    },
    /*
     * @Description: 获取播放视频的时长
     * @MethodAuthor:  liulian
     * @Date: 2023-07-27 14:35:43
     * @param: ''
     */
    getVideoDuration() {
      const videoPlayer = this.$refs.videoPlayer;
      const durationSeconds = Math.floor(videoPlayer.duration);
      const minutes = Math.floor(durationSeconds / 60);
      const seconds = durationSeconds % 60;
      this.timeLong = `${minutes} 分 ${
        seconds < 10 ? "0" + seconds : seconds
      } 秒`;
      this.handleVideoClick();
    },
    getInfo() {
      clientVideoSelectByIdApi(this.videoId).then(async (res) => {
        if (res.code === 200) {
          this.detailInfo = res.data;
          if (res.data.htmlUrl) {
            this.htmlUrl = res.data.htmlUrl.replace(
              "<iframe",
              '<iframe height="100%" width="100%"'
            );
          }

          if (!res.data.htmlUrl && this.detailInfo.videoFileId) {
            let arr = await this.getVideoUrl(this.detailInfo.videoFileId);
            if (arr.length > 0) {
              this.$set(this.detailInfo, "teachingVideoUrl", arr[0].url);
            } else {
              this.$set(this.detailInfo, "teachingVideoUrl", "");
            }
            this.$refs.videoPlayer.load(); // 加载新视频
          }

          // 富文本样式
          if (this.detailInfo.videoContent) {
            this.detailInfo.videoContent = this.replaceWhite(
              this.detailInfo.videoContent
            );
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    replaceWhite(html) {
      // 处理富文本默认图片，视频大小
      let newContent = html
        .replace(/<video[^>]*>/gi, function (match, capture) {
          match = match
            .replace(/style="[^"]+"/gi, "")
            .replace(/style='[^']+'/gi, "");
          match = match
            .replace(/width="[^"]+"/gi, "")
            .replace(/width='[^']+'/gi, "");
          match = match
            .replace(/height="[^"]+"/gi, "")
            .replace(/height='[^']+'/gi, "");
          return match;
        })
        .replace(/<img[^>]*>/gi, function (match, capture) {
          match = match
            .replace(/style="[^"]+"/gi, "")
            .replace(/style='[^']+'/gi, "");
          match = match
            .replace(/width="[^"]+"/gi, "")
            .replace(/width='[^']+'/gi, "");
          match = match
            .replace(/height="[^"]+"/gi, "")
            .replace(/height='[^']+'/gi, "");
          return match;
        });
      newContent = newContent.replace(
        /style="[^"]+"/gi,
        function (match, capture) {
          match = match
            .replace(/width:[^;]+;/gi, "max-width:100%;")
            .replace(/width:[^;]+;/gi, "max-width:100%;");
          return match;
        }
      );
      newContent = newContent.replace(/<br[^>]*\/>/gi, "");
      newContent = newContent
        .replace(
          /\<iframe/gi,
          '<iframe style="width:100%;height:300px;display:inline-block;margin:10rpx auto;"'
        )
        .replace(
          /\<img/gi,
          '<img style="width:100%;height:auto;object-fit:cover;display:inline-block;margin:10rpx auto;"'
        );
      return newContent;
    },
  },

  watch: {
    "$i18n.locale": function () {
      this.getInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.zxyhdVideoDetail {
  min-height: 50vh;
  background-color: rgb(248, 248, 248);
  padding-bottom: 279px;
  position: relative;

  .code_img {
    width: 293px;
    height: 418px;
    position: fixed;
    right: 20px;
    top: 489px;
  }

  .z_top1 {
    background-color: rgb(161, 186, 240);
    height: 240px;
    background-image: url("../../../assets/images/zxyhdImg/zxyhd_banner@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .n_con {
    width: 900px;
    margin: auto;
    min-height: 50vh;
    padding-top: 40px;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 94px;

    .n_c_title {
      line-height: 48px;
    }

    .n_c_time {
      margin-top: 16px;
      color: rgba(29, 29, 31, 0.6);
      margin-bottom: 24px;
    }

    .t_c_l_video {
      height: 405px;
      width: 720px;
      background: #d8d8d8;
      margin-bottom: 27px;

      .c_l_v_box {
        width: 100%;
        height: 100%;
      }
    }

    .n_c_con {
      color: rgba(29, 29, 31, 0.8);
      line-height: 40px;
      white-space: pre-wrap !important;
    }
  }
}
</style>
